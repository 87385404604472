/* You can add global styles to this file, and also import other style files */

@import '@ccmc/scss/core';
// Import ccmc core library
@import "~codemirror/lib/codemirror";
@import "~codemirror/theme/material";
@import "~codemirror/theme/3024-night";
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';
@import "~codemirror/addon/hint/show-hint";
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/lint/lint.css';
@import '~codemirror/addon/hint/show-hint.css';

.CodeMirror {
  width: 100%;
  min-width: 450px;
  height: 394px;
  overflow-x: hidden;
  font-family: Segoe UI;
}

.configCodeMirror .CodeMirror {
  width: 100%;
  min-width: 450px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Segoe UI;
}

.formContainer6 .CodeMirror .CodeMirror-gutters {
  width: 3px;
}

.formContainer6 .CodeMirror pre.CodeMirror-line {
  padding-left: 40px;
}

.formContainer6 .CodeMirror {
  width: 100%;
  min-width: 450px;
  height: 400px;
  font-family: Segoe UI;
}

.findReplaceScript .CodeMirror {
  width: 100%;
  min-width: 450px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Segoe UI;
  padding-top: 5px;
}

.CodeMirror-scrollbar-filler {
  background: #2a3333;
}

table.mat-table tr.mat-row.ng-star-inserted {
  min-height: 30px;
  height: 30px;
  max-height: 30px;
}

.search__panel-dialog>mat-dialog-container.mat-dialog-container,
.collateral-search__dialog>mat-dialog-container.mat-dialog-container {
  background: #e0e0e0;
  width: 60vw;
  overflow: auto;
  border-radius: 10px;
  max-width: 1160px;
}

.collateral-search__dialog>mat-dialog-container.mat-dialog-container {
  background: #e0e0e0;
  width: 60vw;
  overflow: auto;
  border-radius: 10px;
  max-width: 1160px;
}

.horizon-collateral-search__dialog>mat-dialog-container.mat-dialog-container {
  background: #e0e0e0;
  width: 65vw;
  overflow: auto;
  border-radius: 10px;
  max-width: 1250px;
}


.suffix-search__panel-dialog>mat-dialog-container.mat-dialog-container {
  background: #e0e0e0;
  width: 33vw;
  overflow: auto;
  border-radius: 10px;
  max-width: 1160px;
}

.select-sets-dialog>mat-dialog-container.mat-dialog-container {
  width: 20vw;
  border-radius: 10px;
}

.collateral-details__dialog>mat-dialog-container.mat-dialog-container {
  background: #e0e0e0;
}

.edit-user-dialog__panel>mat-dialog-container.mat-dialog-container {
  width: 45vw;
  height: 31vh;
  overflow: auto;
}

.edit-user-assets-dialog__panel>mat-dialog-container.mat-dialog-container {
  width: 45vw;
  height: 40vh;
  max-width: 550px;
  max-height: 300px;
}

.debit-credit__dialog>mat-dialog-container.mat-dialog-container {
  width: 32vw;
  height: 25vh;
}

.add-user-dialog__panel>mat-dialog-container.mat-dialog-container {
  width: 51vw;
  height: 32vh;
  overflow: auto;
}

.add-validation__dialog>mat-dialog-container.mat-dialog-container {
  width: 50vw;
  height: 51vh;
  background-color: #F2F2F1 !important;
}

.forgot-password__results-dialog>mat-dialog-container.mat-dialog-container {
  width: 35rem;
}

.change-password__results-dialog>mat-dialog-container.mat-dialog-container {
  background: rgba(59, 59, 59, 0.8);
  width: 100vw;
  overflow: auto;
  border-radius: 10px;
  max-width: 1200px;
}

.mat_spinner {
  position: absolute;
  top: 50%;
  left: calc(50% - 48px);
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.spinner-container {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: row;
}

.new-spinner {
  display: inline-grid;
  position: fixed;
  top: 40%;
  left: 52%;
  z-index: 10000;
}

.outer-ring {
  display: inline-block;
  width: 594px;
  height: 594px;
}

.outer-ring:after {
  content: " ";
  display: block;
  width: 86px;
  height: 88px;
  margin: 1px;
  border-radius: 50%;
  border: 8px solid #7570D2;
  border-color: #7570D2 transparent #7570D2 transparent;
  animation: outer-ring 2.2s linear infinite;
}

@keyframes outer-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.middle-ring {
  display: inline-block;
  width: 8594px;
  height: 594px;
}

.middle-ring:after {
  content: " ";
  display: block;
  width: 62px;
  height: 62px;
  margin: 1px;
  border-radius: 50%;
  border: 8px solid #7570D2;
  border-color: #7570D2 transparent #7570D2 transparent;
  animation: middle-ring 3.2s linear infinite;
}

@keyframes middle-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inner-ring {
  display: inline-block;
  width: 794px;
  height: 794px;
}

.inner-ring:after {
  content: " ";
  display: block;
  width: 36px;
  height: 35px;
  margin: 1px;
  border-radius: 50%;
  border: 8px solid #7570D2;
  border-color: #7570D2 transparent #7570D2 transparent;
  animation: inner-ring 1.2s linear infinite;
}

@keyframes inner-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fieldDialogBox>mat-dialog-container.mat-dialog-container {
  border-radius: 10px;
  border: 2px solid orange;
}

.tooltip-color {
  background-color: white;
  color: black !important;
  font-size: 10pt !important;
  border: 1px solid rgb(218, 218, 218);
}


.mat-menu-panel {
  @media screen and (max-width: 1919px) {
    zoom: 78%;
    position: relative;
    top: 65px;
  }
}

.change-request__panel-dialog>mat-dialog-container.mat-dialog-container {
  // background: rgba(59, 59, 59);
  overflow: auto;
  border-radius: 10px;
  height: 10%;
  // max-width: 1160px;
}

.new-loan-type__panel-dialog>mat-dialog-container.mat-dialog-container {
  // background: rgba(59, 59, 59);
  border-radius: 10px;
  height: 10%;
  width: 93%;
  // max-width: 1160px;
}

.new-status__panel-dialog>mat-dialog-container.mat-dialog-container {
  width: 35rem;
}


.no-line .mat-form-field-underline {
  display: none;
}
